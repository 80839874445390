var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { Case, Default, Switch } from 'react-when-then';
import { IStatus } from '../../@types/status';
import ContainerLayout from '../../components/ContainerLayout';
import { LinearLoader } from '../../components/Loader';
import { getOneBookStock } from '../../redux/slices/bookStocks/actions';
import { dispatch, useSelector } from '../../redux/store';
import { PATH_DASHBOARD } from '../../routes/paths';
import BookStockForm from '../../sections/@dashboard/BookStocks/BookStockForm';
var BookStocksView = function () {
    var id = useParams().id;
    useEffect(function () {
        dispatch(getOneBookStock(id || ''));
    }, [dispatch, id]);
    var _a = useSelector(function (store) { return store.bookStocks; }), bookStock = _a.bookStock, status = _a.status;
    var isLoading = status === IStatus.LOADING;
    return (_jsx(ContainerLayout, __assign({ helmet: "Book Stock", heading: "View Book Stock", links: [
            {
                name: 'Book Stocks',
                href: PATH_DASHBOARD.books.bookStocks.root,
            },
            { name: 'View Book Stock' },
        ] }, { children: _jsxs(Switch, { children: [_jsx(Case, __assign({ when: isLoading }, { children: _jsx(LinearLoader, {}) })), _jsx(Default, { children: _jsx(BookStockForm, { isView: true, currentBookStock: bookStock || undefined, isEdit: false }, "".concat(bookStock === null || bookStock === void 0 ? void 0 : bookStock._id, "___").concat(new Date())) })] }) })));
};
export default BookStocksView;
